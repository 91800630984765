export const config = {
    API_URL: 'https://api-launchpad.omniflix.market',
    RPC_URL: 'https://rpc.omniflix.network',
    REST_URL: 'https://rest.omniflix.network',
    CHAIN_ID: 'omniflixhub-1',
    CHAIN_NAME: 'OmniFlix Hub',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    PROJECT_ID: '62b14ce3c0ae7ed9aa99057c',
};

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;
export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';
export const NUCLEUS_URL = 'https://omniflix.market';
export const STUDIO_API_URL = 'https://api.omniflix.studio';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_TOTAL = 20;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;

export const socialLinks = {
    TWITTER: 'https://twitter.com/mobix_ai',
    POWERED_BY: 'https://linktr.ee/omniflix',
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    coinType: 118,
    gasPriceStep: {
        low: 0.001,
        average: 0.0025,
        high: 0.025,
    },
};
